import React, {useState,useEffect} from 'react';

import ModalWindowDownload from './ModalWindowDownload';
import PaginationSave from './PaginationSave';
import FoldersMultipleAdd from './FoldersMultipleAdd';

// Material UI
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

// Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

// Refactored 

export default function ContentMenu(props) {

    const {
        saveSearch,
        setSaveName,
        saved,
        openedSave,
        setOpenedSave,
        checkAll,
        chosenItems,
        foundResult,
        userPlan,
        currentFolder,
        pageNumber,
        resultIds,
        userDownloadIds,
        setUserDownloadIds,
        getCookie,
        downloadExcel,
        userCounter,
        setUserCounter,
        allChosen,
        contentMenuHide,
        userFolders,
        setUserFolders,
        setFoldersOpened,
        setChosenItems,
        setSelectedAll,
        setAllChosen,
        singleDownloadState,
        setSingleDownloadState,
        modalDownload,
        setModalDownload
     } = props;

    const [prevSelectedText, setPrevSelectedText] = useState('');

    // Count new download which were added to the download list
    const [newDownloads,setNewDownloads] = useState(0);
    const [oldDownload,setOldDownload] = useState(0);
    
    // Prevent download hidden from first page
    const [cantDownload,setCantDownload] = useState(false);

    // Selected IDs which were selected
    const [notDownloadedSelected,setNotDownloadedSelected] = useState([]);

    const [downloadedSelected,setDownloadedSelected] = useState([]);

    // First three to download during Trial
    const [allowedItems,setAllowedItems] = useState([]);

    const TextNoCompany = "Please select at least one company";
    const TextAllChosen = "All companies are chosen";

    useEffect(() => {
        UpdateChosenCounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[chosenItems]);

    function UpdateChosenCounter() {
        if (chosenItems.length > 0) {
            const itemText = `${chosenItems.length} item${chosenItems.length > 1 ? 's' : ''} selected`;
            setPrevSelectedText(itemText);
        }
    }

    // Press Download and open modal window
    function OpenPreDownload() {
        setCantDownload(false);

        var tempChosenItems = Array.isArray(chosenItems) ? chosenItems : [];
        var intersection = tempChosenItems.filter(item => userDownloadIds.includes(item));
        var notDownloaded = tempChosenItems.filter(item => !userDownloadIds.includes(item));
        setNotDownloadedSelected(notDownloaded);
        setDownloadedSelected(intersection);
        setOldDownload(intersection.length);
        setNewDownloads(Math.abs(intersection.length-chosenItems.length));

        if (!userPlan && currentFolder !== "0" && pageNumber < 2) {
            setAllowedItems(resultIds.slice(0, 3));
            var firstThree = resultIds.slice(0, 3);
            for(let i = 0; i < chosenItems.length; i++) {
                if (!firstThree.includes(chosenItems[i])) {
                    setCantDownload(true);
                }
            }
        }
        
        if (modalDownload === true) {
            setNewDownloads(0);
        }
        setModalDownload(prev=>!prev);
    }

    // Call Modal when Single Download active
    useEffect(()=>{
        if (chosenItems > 0 && singleDownloadState !== 0) {
            OpenPreDownload();
            setSingleDownloadState(0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[singleDownloadState]);

    function UpdateModalDownload(newChosenItems) {
        setChosenItems(newChosenItems);
        setSelectedAll(false);
        setAllChosen(false);
        var intersection = newChosenItems.filter(item => userDownloadIds.includes(item));
        var notDownloaded = newChosenItems.filter(item => !userDownloadIds.includes(item));
        setNotDownloadedSelected(notDownloaded);
        setDownloadedSelected(intersection);
        setOldDownload(intersection.length);
        setNewDownloads(Math.abs(intersection.length-newChosenItems.length));
        UpdateChosenCounter();
    }

    return (

        <div className='content-menu'>

            <ModalWindowDownload 
            OpenPreDownload={OpenPreDownload}
            newDownloads={newDownloads}
            setNewDownloads={setNewDownloads}
            setOldDownload={setOldDownload}
            userPlan={userPlan}
            currentFolder={currentFolder}
            pageNumber={pageNumber}
            cantDownload={cantDownload}
            userCounter={userCounter}
            chosenItems={chosenItems}
            setChosenItems={setChosenItems}
            oldDownload={oldDownload}
            modalDownload={modalDownload}
            setModalDownload={setModalDownload}
            getCookie={getCookie}
            downloadExcel={downloadExcel}
            UpdateChosenCounter={UpdateChosenCounter}
            setSelectedAll={setSelectedAll}
            setAllChosen={setAllChosen}
            notDownloadedSelected={notDownloadedSelected}
            setUserCounter={setUserCounter}
            setUserDownloadIds={setUserDownloadIds}
            UpdateModalDownload={UpdateModalDownload}
            downloadedSelected={downloadedSelected}
            setCantDownload={setCantDownload}
            allowedItems={allowedItems}
            />

            <div className='content-menu__checkbox'>
                <Checkbox
                disabled={contentMenuHide}
                id='multi-select-checkbox'
                checked={allChosen}
                onChange={()=>checkAll('pagination')}
                className='checkbox__input'
                color="primary_grey_light"
                />
                
                <div className='headings__bookmark'>
                </div>
            </div>

            <Tooltip title={chosenItems?.length !== foundResult ? "" : TextAllChosen}>

            <div>
            <Button
            variant="contained"
            color="primary_grey_light"
            onClick={()=>checkAll('select-all')}
            startIcon={<DoneAllIcon />}
            className='content-menu__expand-select content-menu__button'
            size="small"
            disabled={contentMenuHide || chosenItems?.length === foundResult}
            >
                <span className='expand-select__text'>
                Select All
                </span>
            </Button>
            </div>
            </Tooltip>

            <Fade 
            in={chosenItems.length > 0} 
            timeout={{ enter: 400, exit: 300 }}
            easing={{ enter: 'ease-in-out', exit: 'ease-in-out' }}
            >
            <div>
                <Button
                disabled={contentMenuHide}
                variant="contained"
                color="primary_red_light"
                onClick={()=>checkAll('deselect-all')}
                endIcon={<CloseIcon />}
                className='content-menu__expand-select content-menu__button'
                size="small" 
                >
                {prevSelectedText}
                </Button>
            </div>
            </Fade>

            <div className='content-menu__save'>

                <PaginationSave
                saveSearch={saveSearch} 
                setSaveName={setSaveName}
                saved={saved}
                openedSave={openedSave}
                setOpenedSave={setOpenedSave}
                contentMenuHide={contentMenuHide}
                />

            </div>


            <Tooltip title={chosenItems.length > 0 ? "" : "Please select at least one company"}>
            <div className='content-menu__download'>
                    <Button
                    disabled={contentMenuHide || chosenItems.length < 1}
                    className='content-menu__button' 
                    onClick={OpenPreDownload}
                    variant="contained"
                    color={'primary_grey_light'}
                    startIcon={<DownloadIcon />}
                    >
                        Download
                    </Button>
            </div>
            </Tooltip>

            <FoldersMultipleAdd 
            userFolders={userFolders}
            setUserFolders={setUserFolders}
            getCookie={getCookie}
            chosenItems={chosenItems}
            setFoldersOpened={setFoldersOpened}
            contentMenuHide={contentMenuHide}
            TextNoCompany={TextNoCompany}
            />

        </div>

    );
                  
    
}