import { useEffect } from 'react';

// Refactored

export default function CheckUser(props) {

    const {setSavedSearches,setUserFolders,setUserDetails,getCookie,setCheat} = props;

    //\\//\\// Decrypt token from Cookies \\//\\//\\
    function decryptToken() {
        
        var urlApi = process.env.REACT_APP_URL_API + 'auth';
        const queryToken = {
            "data": 'token',
            "token": getCookie(),
        };
        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                // 'Content-Type': 'application/json',
                },
            cache: 'no-store',
            body: JSON.stringify(queryToken)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            // console.log(data['user_details']);
            setUserDetails(data['user_details']);
            setSavedSearches(data['user_saves']);
            setUserFolders(data['user_folders']);
            setCheat(data['cheat']);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(()=>{
        decryptToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

}