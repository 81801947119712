import React from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import DownloadingIcon from '@mui/icons-material/Downloading';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import logoPng from'./../../assets/vinaty-logo-135.png';
import FoldersList from './../FoldersList';
import UserSaves from './../UserSaves';

export default function Counters(props) {

    const {
        toggleSidebar,
        chooseFolder,
        openSaves,
        openFolders,
        foldersOpened,
        userFolders,
        setUserFolders,
        getCookie,
        filters,
        newFolderSlide,
        setNewFolderSlide,
        currentFolderEdit,
        setCurrentFolderEdit,
        savesOpened,
        savedSearches,
        deleteSave,
        setSavesOpened,
        setFilters,
        setTextSearch,
        setTextSearchParams,
        userDownloadIds,
        openBookmarks,
        enableAdminDashboard,
        admin,
        bookmarksCounter
    } = props;

    return (

    <div id='sidebar' className='sidebar'>

        <div className='sidebar__gradient'>

            <div className="close-sidebar" onClick={toggleSidebar}>
            <IconButton size={'large'} aria-label="delete" color="primary">
                <CloseIcon />
            </IconButton>
            </div>

            <div className='sidebar__top-container'>

                <div className='sidebar__vinaty'>

                    <img src={logoPng} alt="Vinaty Logo"/>

                </div>
                
            </div>

            <div className='sidebar__center'>

                <Button 
                size={'large'} 
                sx={{fontSize: '18px', fontWeight: 'bold', textTransform: 'none'}} 
                className={'sidebar__button' + (filters['folder'] === "" ? ' sidebar__button--active' : '')} 
                color='primary' 
                startIcon={<HomeIcon />}
                onClick={()=>chooseFolder('')}
                >
                Main Catalog
                </Button>

                <Button 
                size={'large'} 
                sx={{fontSize: '18px', fontWeight: 'bold', textTransform: 'none'}} 
                className={'sidebar__button' + (filters['folder'] === "0" ? ' sidebar__button--active' : '')} 
                color='primary' 
                startIcon={<DownloadingIcon />}
                onClick={()=>chooseFolder('0')}
                >
                Downloads 
                <span className='counter-button'>
                    {userDownloadIds?.length > 1 ? ' (' + userDownloadIds?.length + ')' : ''}
                </span>
                </Button>

                <Button 
                size={'large'} 
                sx={{fontSize: '18px', fontWeight: 'bold', textTransform: 'none'}} 
                className={'sidebar__button button-saved-search' + (savesOpened ? ' sidebar__button--active' : '')} 
                color='primary' 
                startIcon={<SavedSearchIcon />}
                onClick={openSaves}
                >
                Saved Searches
                </Button>
                
                <Button 
                size={'large'} 
                sx={{fontSize: '18px', fontWeight: 'bold', textTransform: 'none'}} 
                className={'sidebar__button' + (filters['folder'] === "-1" ? ' sidebar__button--active' : '')} 
                color='primary' 
                startIcon={<BookmarkBorderIcon />}
                onClick={openBookmarks}
                >
                Bookmarks
                <span className='counter-button'>
                    {' (' + bookmarksCounter + ')'}
                </span>
                </Button>

                <Button 
                size={'large'} 
                sx={{fontSize: '18px', fontWeight: 'bold', textTransform: 'none'}} 
                className={
                'sidebar__button'+ 
                ((filters['folder'] !== "-1" && filters['folder'] !== "0" && filters['folder'] !== "") ? ' sidebar__button--active' : '') + 
                (foldersOpened ? ' sidebar__button--opened' : '')}
                color='primary' 
                startIcon={<FolderSpecialIcon/>}
                endIcon={<ChevronRightIcon className='end-arrow'/>}
                onClick={openFolders}
                >
                Folders
                </Button>

                <FoldersList
                foldersOpened={foldersOpened}
                userFolders={userFolders}
                setUserFolders={setUserFolders}
                getCookie={getCookie}
                chooseFolder={chooseFolder}
                filters={filters}
                setSavesOpened={setSavesOpened}
                newFolderSlide={newFolderSlide}
                setNewFolderSlide={setNewFolderSlide}
                currentFolderEdit={currentFolderEdit}
                setCurrentFolderEdit={setCurrentFolderEdit}
                />
            </div>

            <div className={"sidebar-absolute-slide" + (!savesOpened ? ' sidebar-absolute-slide--active' : '')}>
                
                <div className='sidebar-absolute'>
                <UserSaves 
                savedSearches={savedSearches} 
                deleteSave={deleteSave} 
                setSavesOpened={setSavesOpened} 
                setFilters={setFilters}
                setTextSearch={setTextSearch}
                setTextSearchParams={setTextSearchParams}
                />
                </div>

            </div>
            
            {admin ?
            <Button 
            className='admin-dashboard-button'
            onClick={enableAdminDashboard}
            >
                <span className='admin-dashboard-button__text'>
                    Go to Dashboard
                </span>
            </Button>
            : null}

        </div>


    </div>

    );
}