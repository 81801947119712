import React, { useState } from 'react';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Material UI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';

import FolderIcon from '@mui/icons-material/Folder';
import EmailIcon from '@mui/icons-material/Email';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import TableRowsIcon from '@mui/icons-material/TableRows';
import StarIcon from '@mui/icons-material/Star';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const iconsArray = [
    {icon: FolderIcon,name:'FolderIcon'},
    {icon: EmailIcon,name:'EmailIcon'},
    {icon: DriveFolderUploadIcon,name:'DriveFolderUploadIcon'},
    {icon: FolderSpecialIcon,name:'FolderSpecialIcon'},
    {icon: FindInPageIcon,name:'FindInPageIcon'},
    {icon: FolderOffIcon,name:'FolderOffIcon'},
    {icon: TableRowsIcon,name:'TableRowsIcon'},
    {icon: StarIcon,name:'StarIcon'},
    {icon: HourglassEmptyIcon,name:'HourglassEmptyIcon'},
    {icon: DoneIcon,name:'DoneIcon'},
    {icon: CancelIcon,name:'CancelIcon'},
    {icon: MonetizationOnIcon,name:'MonetizationOnIcon'},
    {icon: NotificationImportantIcon,name:'NotificationImportantIcon'},
    {icon: FeedbackIcon,name:'FeedbackIcon'},
    {icon: LocalPhoneIcon,name:'LocalPhoneIcon'},
    {icon: BookmarkIcon,name:'BookmarkIcon'},
]

export default function FoldersList(props) {

    const {
        filters,
        foldersOpened,
        userFolders,
        setUserFolders,
        getCookie,
        chooseFolder,
        setSavesOpened,
        newFolderSlide,
        setNewFolderSlide,
        currentFolderEdit,
        setCurrentFolderEdit
    } = props;

    // Vars 
    var urlApi;

    // States
    const [folderName,setFolderName] = useState('New Folder');
    // const [folderContent,setFolderContent] = useState('');

    // Chosen color
    const [chosenColor,setChosenColor] = useState('#c683d2');
    // Colors to shose array
    const colorsArray = ['#df6b69','#ea7fa2','#c683d2','#a389d2','#8390de','#68b6f5','#5ed1df','#66cfc5','#93d296','#e3ec8a','#f9bb61','#ff9d7e','#a98e84','#b4bfc4','#6a6a6a','#c3dcf2'];

    // Chosen Icon
    const [chosenIcon,setChosenIcon] = useState('FolderIcon');
    // const iconsArray = [<FolderIcon/>,<FolderIcon/>];


    // Function to Open Creation of Folder
    function openCreateFolder(folderId){

        setSavesOpened(false);

        if (folderId === currentFolderEdit) {
            setNewFolderSlide(current => !current);
            setCurrentFolderEdit(0);
        } else {
            setNewFolderSlide(true);
            setCurrentFolderEdit(folderId);
        }

        // Set data of chosen folder to form
        if (folderId !== 0) {
            userFolders.map((folder)=>{
                if (folder.folder_id === folderId) {
                    setFolderName(folder.name);
                    setChosenColor(folder.color);
                    setChosenIcon(folder.icon);
                    return null;
                } else {
                    return null;
                }
            });
        } else {
            setFolderName('New Folder');
            setChosenColor('#ab47bc');
            setChosenIcon('FolderIcon');
        }
    }

    // Function to create Folder
    function createFolder() {

        setNewFolderSlide(false);

        urlApi = process.env.REACT_APP_URL_API + 'folders';

        const querySave = {
            "data": "new_folder",
            "folder_name": folderName,
            "folder_icon": chosenIcon,
            "folder_color": chosenColor,
            "folder_content": '',
            "folder_id": currentFolderEdit,
            "token": getCookie()
        };
        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(querySave)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setUserFolders(data);
        })
        .catch((error) => {
            // window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code f001');
        });
    }

    // Render icon in the list of folders
    function findIcon(item) {
        for(let i = 0; i < iconsArray.length; i++) {
            if (iconsArray[i].name === item.icon) {
                var icon = iconsArray[i];
                return(
                    <icon.icon size={'medium'} sx={{color: item.color, fontSize: '28px'}}/>
                );
            }
        }
    }

    //changeOrderFolders
    // function changeOrderFolders(result) {
    //     if (!result.destination) return;
    //     const items = Array.from(userFolders);
    //     const [reorderedItem] = items.splice(result.source.index, 1);
    //     items.splice(result.destination.index, 0, reorderedItem);

    //     var new_folder_array = [];
    //     items.forEach((item,index)=>{
    //         item.folder_order = index + 1;
    //         new_folder_array.push(item);
    //     });
    //     setUserFolders(new_folder_array);

    //     // New order to server
    //     urlApi = process.env.REACT_APP_URL_API + 'folders';

    //     const queryChangeOrder = {
    //         "data": "change_order",
    //         "folders": new_folder_array,
    //         "token": getCookie()
    //     };
    //     fetch(urlApi, {
    //         method: 'POST',
    //         headers: {
    //             Authorization: process.env.REACT_APP_TOKEN_API,
    //             },
    //         cache: 'no-store',
    //         body: JSON.stringify(queryChangeOrder)
    //     })
    //     .then(response => {
    //         return response.json();
    //     })
    //     .then(data => {
    //         // setUserFolders(data);
    //     })
    //     .catch((error) => {
    //         // window.location.replace("https://vinaty.com/login/");
    //         alert('Something went wrong during save of the content. Error code f002');
    //     });
    // }

    function deleteFolder() {

        setSavesOpened(false);

        setNewFolderSlide(false);

        urlApi = process.env.REACT_APP_URL_API + 'folders';

        const querySave = {
            "data": "delete_folder",
            "folder_id": currentFolderEdit,
            "token": getCookie()
        };
        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(querySave)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setUserFolders(data);
        })
        .catch((error) => {
            window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code f003');
        });
    }

    // const getItemStyle = (isDragging, draggableStyle) => ({
    //     userSelect: "none",
    //     // change background colour if dragging
    //     background: isDragging ? "rgba(141, 82, 137, 0.25)" : "white",
    //     // styles we need to apply on draggables
    //     ...draggableStyle
    //   });
      
    //   const getListStyle = isDraggingOver => ({
    //     background: isDraggingOver ? "rgba(141, 82, 137, 0.10)" : "transparent",
    //   });

    return (
        <>
        <Collapse className='folders' in={foldersOpened}>

            <div className='folders__folders'>
                {userFolders.length < 1 ?
                "You don't have folders yet"
                : 
                // <DragDropContext onDragEnd={changeOrderFolders}>
                // <Droppable droppableId="droppable">
                    // {(provided,snapshot)=>(
                        <div className='folders__draggable-context'
                        // {...provided.droppableProps}
                        // ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {userFolders.map((item,index)=>{
                                return(
                                    <div key={item.folder_id} style={{position: 'relative'}}>
                                    {/* <Draggable draggableId={item.folder_id} index={index}>
                                        {(provided,snapshot)=>( */}
                                            <div 
                                            // ref={provided.innerRef} 
                                            // {...provided.draggableProps} 
                                            // {...provided.dragHandleProps}
                                            // style={getItemStyle(
                                            //     snapshot.isDragging,
                                            //     provided.draggableProps.style
                                            //   )}
                                            onClick={()=>chooseFolder(item.folder_id)} 
                                            className={'folders__item' + (filters['folder'] === item.folder_id ? ' folder--active' : '')}
                                            data-folder={item.folder_id}
                                            >
                                                {findIcon(item)}
                                                <span className='folders__item-name'>
                                                    {item.name}
                                                </span>
                                                
                                                {/* {provided.placeholder} */}
                                            </div>
                                        {/* )}
                                    </Draggable> */}
                                    
                                    <span 
                                    onClick={()=>openCreateFolder(item.folder_id)}
                                    className='folders__edit'>
                                        <EditIcon />
                                    </span>

                                    </div>
                                );
                            })}
                            {/* {provided.placeholder} */}
                        </div>
                //     )}
                // </Droppable>
                // </DragDropContext>
                }
            </div>
            
            
            {userFolders.length < 5?
                <>
                <Button 
                size={'small'} 
                sx={{textTransform: 'none',marginTop: '20px', width: '74%', marginLeft:'14%',borderRadius:'2px'}} 
                className='sidebar__create-folder'
                color='primary_grey_light' 
                startIcon={<CreateNewFolderIcon/>}
                // onClick={createFolder}
                onClick={()=>openCreateFolder(0)}
                variant="contained"
                >
                Create Folder
                </Button>

                <span className='folders__span'>
                    Up to 5 folders
                </span>
                </>
            : <span className='folders__span'>You have reached the maximum number of folders</span>}

        </Collapse>

            {/* Create new folder Slide */}
            <div className={"sidebar-absolute-slide slide__folder" + (!newFolderSlide ? ' sidebar-absolute-slide--active' : '')}>
              <div className='sidebar-absolute'>

                <div className='folder__heading-container'>

                    <p className='folder__heading'>
                        {(currentFolderEdit === 0 || currentFolderEdit === '0') ? 'Create new folder' : 'Edit folder'}
                    </p>

                    <IconButton onClick={()=>openCreateFolder(currentFolderEdit)} aria-label="close" size="medium">
                        <CloseIcon fontSize="inherit" />
                    </IconButton>

                </div>

                {/* NAME FOLDER */}

                <div className='folder__naming-container'>

                    <TextField
                    label="Folder name"
                    id="outlined-size-small"
                    // defaultValue="New folder"
                    size="small"
                    className='folder-name-text-field'
                    color={'primary'}
                    value={folderName}
                    onChange={(event)=>setFolderName(event.target.value)}
                    />

                </div>
                
                {/* COLOR PICKER */}

                <div className='folder__color-container'>
                    
                    <div className='folder__color-picker'>
                        <p>Choose color</p>
                        <CircleIcon sx={{color: chosenColor}}/>
                    </div>

                    {colorsArray.map((color,index)=>{
                        return(

                            <IconButton 
                            key={index}
                            onClick={()=>setChosenColor(color)} 
                            size={'medium'} 
                            aria-label="choose color"
                            className={(chosenColor === color ? 'color--active' : '')}
                            >

                                <CircleIcon size={'medium'} sx={{color: color, fontSize: '28px'}}/>
                            
                            </IconButton>

                        );
                    })}

                </div>
                
                {/* ICON PICKER */}
                
                <div className='folder__icon-container'>
                    
                    <div className='folder__icon-picker'>
                        <p>Choose icon</p>
                    </div>

                    {iconsArray.map((icon,index)=>{
                        return(

                            <IconButton 
                            key={index}
                            onClick={()=>setChosenIcon(icon.name)} 
                            size={'medium'} 
                            aria-label="choose icon"
                            className={(chosenIcon === icon.name ? 'icon--active' : '')}
                            >
                                <icon.icon size={'medium'} sx={{color: chosenColor, fontSize: '28px'}}/>
                            </IconButton>

                        );
                    })}

                </div>

                {/* SAVE FOLDER */}
                
                <div className='folder__button-container'>

                    {currentFolderEdit !== 0 ?
                        <Button 
                        className='folder__button-delete'
                        size={'small'} 
                        sx={{marginTop: '15px',marginBottom: '15px'}} 
                        // className='save-folder-button'
                        color='red_dark' 
                        startIcon={<DeleteIcon/>}
                        onClick={deleteFolder}
                        >
                            Delete
                        </Button>
                    : null}

                    <Button 
                    size={'small'} 
                    sx={{textTransform: 'none', marginTop: '15px',marginBottom: '15px'}} 
                    // className='save-folder-button'
                    color='primary_grey_light' 
                    startIcon={<CreateNewFolderIcon/>}
                    onClick={createFolder}
                    // onClick={openCreateFolder}
                    variant="contained"
                    >
                        Save Folder
                    </Button>

                </div>

              </div>

            </div>

        </>
    );
}