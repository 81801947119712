import React from 'react';
import Button from '@mui/material/Button';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
// import IconButton from '@mui/material/IconButton';

export default function TrustPilot(props) {

    // const [openListNews,setOpenListNews] = useState(false);

    return (

        <div className='trust-pilot'>

            <div className='trust-pilot__stars'>
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
            </div>

            <Button 
            variant="contained" 
            size="small" 
            // startIcon={<ArrowCircleUpSharpIcon />} 
            target="_blank" 
            href="https://www.trustpilot.com/review/vinaty.com"
            className='trust-pilot__button'
            color={'green_menu'}
            >
                <span className='button-text'>Trust Pilot</span>
            </Button>

            <span className='trust-pilot__text'>

            Is the Vinaty database helpful for you? Share your opinion on TrustPilot!
        
            </span>

        </div>
    );
}