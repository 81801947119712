import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Select from 'react-select';

export default function PaginationPagination(props) {

    const {totalResult,perPage,setPerPage,pageNumber,setPageNumber} = props;
    const [numberPages,setNumberPages] = useState(10);
    const [defaultNumPag,setDefaultNumPag] = useState(3);

    // Recalculate number of page for pagination
    useEffect(()=>{
        if (totalResult !== false) {
            setNumberPages(Math.ceil(totalResult / perPage));
        }
    },[totalResult,perPage]);

    // const [page, setPage] = useState(1);
    const handleChange = (event,value) => {
        setPageNumber(value);
    };

    // Update state with number of items per page. This will update request
    function pageNumHandler(event) {
        setPageNumber(1);
        setPerPage(event.value);
    }

    useEffect(()=>{
        if (perPage === 10) {
            setDefaultNumPag(0);
        } else if (perPage === 25) {
            setDefaultNumPag(1);
        } else if (perPage === 50) {
            setDefaultNumPag(2);
        } else if (perPage === 75) {
            setDefaultNumPag(3);
        } else if (perPage === 100) {
            setDefaultNumPag(4);
        }
    },[perPage]);

    const pagesNumOptions = [
        {
          label: '10',
          value: 10,
        },
        {
          label: '25',
          value: 25,
        },
        {
            label: '50',
            value: 50,
        },
        {
            label: '75',
            value: 75,
        },
        {
            label: '100',
            value: 100,
        },
      ];

    return (

        <div className='pagination__pagination'>

            <Stack spacing={2}>
                {/* <Typography>Page: {page}</Typography> */}
                <Pagination
                showFirstButton showLastButton
                // boundaryCount={2}
                siblingCount={1}
                count={numberPages} 
                page={pageNumber} 
                onChange={(event,value) => handleChange(event,value)}
                sx={{
                    '& .MuiPaginationItem-icon': {
                      color: 'rgb(137, 125, 136)', // Цвет стрелочек для кнопок в начало и в конец
                      paddingBottom: '2px',
                      paddingTop: '0px',
                    },
                    '& .MuiPaginationItem-previousNext': {
                      color: 'rgb(137, 125, 136)', // Цвет стрелочек для кнопок "Предыдущая" и "Следующая"
                    },
                  }}
                />
            </Stack>

            <div>

            <Select
                className="number-pages"
                classNamePrefix="filter-select"
                defaultValue={pagesNumOptions[1]}
                value={pagesNumOptions[defaultNumPag]}
                options={pagesNumOptions}
                onChange={event => pageNumHandler(event)}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(141, 82, 137, 0.10)',
                      primary: 'rgba(141, 82, 137, 0.25)',
                      primary50: 'rgba(141, 82, 137, 0.35)'
                    },
                  })}
                
            />


            </div>
            
        </div>
    );
}