import React from 'react';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';

export default function PaginationTotalResults(props) {

    const {
        foundResult,
        totalResult,
        clearFilters,
        // userDetails,
        // currentFolder,
    } = props;
    
    return (

        <div className='pagination__total-results'>

            <div className={'pagination__clear' + ((foundResult < totalResult) ? ' pagination__clear--active' : '')}>
                <Button 
                onClick={()=>clearFilters()} 
                variant="contained"
                startIcon={<ReplayIcon />}
                color={'primary_grey_light'}
                size={'small'}
                className='pagination__button-clear'
                >
                    Clear Filters
                </Button>
            </div>

            <div className='pagination__block'>

                {totalResult !== false ?
                    <>Found {foundResult} distributor{foundResult > 1 ? 's' : ''}</>
                    : 'loader'
                }
            </div>
            
        </div>
    );
}