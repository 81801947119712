import React, { useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

import FolderIcon from '@mui/icons-material/Folder';
import EmailIcon from '@mui/icons-material/Email';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import TableRowsIcon from '@mui/icons-material/TableRows';
import StarIcon from '@mui/icons-material/Star';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function FoldersAction(props) {

    const {
        item,
        typeActionButton,
        userFolders,
        setUserFolders,
        getCookie,
    } = props;

    const [open, setOpen] = useState(false);

    var urlApi;

    const handleClick = (event) => {
        event.stopPropagation()
        setOpen(!open);
    };

    const iconsArray = [
        {icon: FolderIcon,name:'FolderIcon'},
        {icon: EmailIcon,name:'EmailIcon'},
        {icon: DriveFolderUploadIcon,name:'DriveFolderUploadIcon'},
        {icon: FolderSpecialIcon,name:'FolderSpecialIcon'},
        {icon: FindInPageIcon,name:'FindInPageIcon'},
        {icon: FolderOffIcon,name:'FolderOffIcon'},
        {icon: TableRowsIcon,name:'TableRowsIcon'},
        {icon: StarIcon,name:'StarIcon'},
        {icon: HourglassEmptyIcon,name:'HourglassEmptyIcon'},
        {icon: DoneIcon,name:'DoneIcon'},
        {icon: CancelIcon,name:'CancelIcon'},
        {icon: MonetizationOnIcon,name:'MonetizationOnIcon'},
        {icon: NotificationImportantIcon,name:'NotificationImportantIcon'},
        {icon: FeedbackIcon,name:'FeedbackIcon'},
        {icon: LocalPhoneIcon,name:'LocalPhoneIcon'},
        {icon: BookmarkIcon,name:'BookmarkIcon'},
    ]

    // Render icon in the list of folders
    function findIcon(item) {
        for(let i = 0; i < iconsArray.length; i++) {
            if (iconsArray[i].name === item.icon) {
                var icon = iconsArray[i];
                return(
                    <icon.icon size={'small'} sx={{color: item.color, fontSize: '24px'}}/>
                );
            }
        }
    }

    // Action button add item to the folder
    function addItemToFolder(folder,action) {
        urlApi = process.env.REACT_APP_URL_API + 'folders';

        const queryAddToFolder = {
            "data": "item_to_folder",
            "action": action,
            "item_id": [item.id],
            "folder_id": folder.folder_id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryAddToFolder)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setUserFolders(data);
        })
        .catch((error) => {
            window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code p003');
        });
    }

    // Check if that ITEM is in the folder or not
    function removeOrAdd(userFolders,folder) {
        for(let i = 0; i < userFolders?.length; i++){
            if (userFolders[i]['folder_id'] === folder['folder_id']) {
                var thisFolderArr = userFolders[i]['content'].split(",");
                thisFolderArr.includes(item.id);
                    if (thisFolderArr.includes(item.id)) {
                        
                        // If ITEM was added before to this FOLDER
                        return(
                            <ListItemButton 
                            // key={key} 
                            id={folder.folder_id} 
                            sx={{ pl: 4 }}
                            className='folder-action-btn'
                            onClick={()=>addItemToFolder(folder,'remove')}
                            >
                                <ListItemIcon >
                                    {findIcon(folder)}
                                </ListItemIcon>

                                <ListItemText primary={folder.name} />

                                    <ListItemIcon sx={{ justifyContent: 'flex-end'}}>
                                        <RemoveCircleIcon color={'red_dark'}/>
                                    </ListItemIcon>

                            </ListItemButton>
                
                        );
                    } else {

                        return(
                            <ListItemButton 
                            // key={key} 
                            id={folder.folder_id} 
                            sx={{ pl: 4 }}
                            className='folder-action-btn'
                            onClick={()=>addItemToFolder(folder,'add')}
                            >
                                <ListItemIcon >
                                    {findIcon(folder)}
                                </ListItemIcon>

                                <ListItemText primary={folder.name} />
                                
                                    <ListItemIcon sx={{ justifyContent: 'flex-end'}}>
                                        <AddCircleIcon color={'green_dark'}/>
                                    </ListItemIcon>

                            </ListItemButton>
                
                        );
                    }
            }
        }
    }

    function closeFolderList() {
        if (open) {
            setOpen(false);
        }
    }

    return (
        <div id={'single-action-' + item.id} className={(typeActionButton !== 2 && typeActionButton !== '2') ? 'pagination__actions' : 'distributor__actions'}>

            {/* <Collapse in={expanded === item.id} timeout="auto" unmountOnExit> */}
                <ListItemButton 
                className={'distributor__folder-action' + (userFolders.length < 1 ? ' distributor__folder-action--inactive' : '')} 
                onClick={userFolders.length > 0 ? (event) => handleClick(event) : null}
                >
                    
                    <ListItemIcon>
                        <DriveFileMoveIcon color={'primary_grey_light'}/>
                    </ListItemIcon>

                    <ListItemText primary="Folders Management" />
                    {open ? <ExpandLess /> : <ExpandMore />}

                </ListItemButton>


                <ClickAwayListener onClickAway={closeFolderList}>
                <Collapse className="collapsed-inside" in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {userFolders?.map((folder,key)=>{
                            return(
                                <div key={key}>
                                {removeOrAdd(userFolders,folder)}
                                </div>

                            );
                        })}

                    </List>
                </Collapse>
                </ClickAwayListener>
            {/* </Collapse> */}

        </div>
    );
}